import React from "react"
import styled from "styled-components"
import Header from "../components/Header"
import Footer from "../components/Footer"
// import HomeHero from "../components/HomeHero"
// import PremiumSection from "../components/PremiumSection"
// import DescriptionSection from "../components/DescriptionSection"
// import Featured from "../components/Featured"
// import MassageTypes from "../components/MassageTypes"
// import ReviewsSection from "../components/ReviewsSection"
// import Questions from "../components/Questions"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
const Container = styled.div`
  .bg-color {
    position: absolute;
    top: 0;
    left: 0.5%;
    width: 99%;
    height: 20em;
    background-color: #feede2;
  }

  .dotted-pattern {
    position: absolute;
    top: 0;
    left: 18%;
    width: 45%;
    max-width: 700px;
    height: 20em;
    background-image: url(${require("../images/dotted-pattern.svg")});
  }
  .header-text {
    position: absolute;
    top: 10em;
    left: 2em;
  }

  h2 {
    line-height: 1.4em;
    margin-top: 0.35em;
    color: #1d2b3f;
    font-family: "Vidaloka", serif;
    font-size: 40px;
    letter-spacing: 0;
    line-height: 49px;
    font-weight: 400;
  }
  p {
    font-size: 16px;
    line-height: 1.6em;
    margin: 1.5em 0;
  }
  .body-text {
    margin-top: 20em;
    padding: 2em;
  }
  .bold {
    font-weight: bold;
  }
`

// const RichText = ({ data }) => {
//     const Bold = ({ children }) => <span className="bold">{children}</span>
//     const Text = ({ children }) => <p className="align-center">{children}</p>
//     const options = {
//       renderMark: {
//         [MARKS.BOLD]: text => <Bold>{text}</Bold>,
//       },
//       renderNode: {
//         [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
//       },
//     }

const additionalPage = ({ children, data }) => {
  const Bold = ({ children }) => <span className="bold">{children}</span>
  const Text = ({ children }) => <p className="align-center">{children}</p>
  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    },
  }
  return (
    <Container>
      <Header />
      <div className="bg-color" />
      <div className="dotted-pattern" />
      <div className="header-text">
        <h1>{data.contentfulAdditionalPages.name}</h1>
      </div>
      <div className="body-text">
        {documentToReactComponents(
          data.contentfulAdditionalPages.content.json,
          options
        )}
      </div>
      <Footer />
    </Container>
  )
}

export default additionalPage

export const pageQuery = graphql`
  query additionalPageBySlug($slug: String!) {
    contentfulAdditionalPages(slug: { eq: $slug }) {
      name
      content {
        json
      }
    }
  }
`
